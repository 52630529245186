/* Estilos generales */
section.contacto {
    background: #1a191d;
    min-height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    z-index: 1;
    padding: 0 40px;
    padding-bottom: 100px;
  }
  
  .contacto > h2 {
    margin-bottom: 70px;
    margin-top: 100px;
  }
  
  .contacto > h2 > span {
    text-align: center;
    color: white;
    font-family: "Poppins", Sans-serif;
    font-size: 62px;
    font-weight: 600;
    line-height: 1.1em;
    display: block;
  }
  
  .contactFormContainer {
    max-width: 1100px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* border: 1px solid red; */
  }
  
  .contactFormContainer img {
    width: 250px;
    margin: 40px auto;
  }
  
  /* Estilos de los inputs */
  .contactFormContainer form > input,
  textarea,
  input::placeholder,
  textarea::placeholder {
    display: block;
    width: 80%;
    margin: 0 auto;
    padding: 10px;
    padding-left: 30px; /* Espacio para el icono */
    margin-bottom: 10px;
    background: rgb(255, 255, 255);
    color: #000;
    font-family: 'Poppins';
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border-radius: 20px;
    border: none;
    background-repeat: no-repeat;
    background-position: 10px center;
  }
  
  /* Iconos específicos para cada input */
  .contactFormContainer form > input[name="nombre"] {
    background-image: url('../images/user.png'); /* Cambia la ruta al icono correspondiente */
    background-repeat: no-repeat;
    background-size: 15px;
  }
  
  .contactFormContainer form > input[name="emailRmt"] {
    background-image: url('../images/email.png'); /* Cambia la ruta al icono correspondiente */
    background-repeat: no-repeat;
    background-size: 15px;
  }

  .btnMailContainer {
    width: fit-content;
    margin: 20px auto;
  }
  
  #btnMail {
    width: 270px;
    height: 60px;
    border: none;
    background: #2f2f2f;
    color: #fff;
    font-size: 22px;
    border-radius: 40px;
    text-align: center;
    box-shadow: 0 6px 20px -5px rgba(0,0,0,0.4);
    cursor: pointer;
    transition: .4s!important;
  }


.activeMail, #btnMail:hover {
    background: #740cdc80!important;
    transition: .4s!important;
    /* cursor:not-allowed; */
}

@media screen and (max-width: 850px) {
    .contactFormContainer img {
        width: 100px;
        margin: 40px auto;
      }

    .contactFormContainer {
        grid-template-columns: 1fr;
    }
    
    .contactFormContainer form > input,
  textarea,
  input::placeholder,
  textarea::placeholder {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    padding-left: 30px; /* Espacio para el icono */
    margin-bottom: 10px;
    background: rgb(255, 255, 255);
    color: #000;
    font-family: 'Poppins';

    border-radius: 15px;
    border: none;
    background-repeat: no-repeat;
    background-position: 10px center;
  }
    .contacto > h2 {
        font-size: 10vw;
        margin-bottom: 0px;
        margin-top: 40px;
      }
}