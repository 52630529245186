nav {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 40px 80px;
    z-index: 9;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    transition: padding 0.3s, background-color 0.3s;
}

nav.fixed {
    position: fixed;
    padding: 20px 40px 0px 40px;
    background: rgba(26, 26, 26, 0.8);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    top: 0;
    width: 100%;
}

nav.fixed img {
    display: none;
}

nav > a {
    position: absolute;
    left: 80px;
    top: 40px;
}



#navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    counter-reset: numbers;
}

#navbar li {
    list-style: none;
    margin: 0 20px;
    position: relative;
}

#navbar li a {
    text-decoration: none;
    color: white;
    font-size: 1.1rem;
    font-weight: 500;
    transition: .3s ease-in-out;
    letter-spacing: -1px;
}

.fixed #navbar li a {
    text-decoration: none;
    color: #dd00ff;
    font-size: 1.1rem;
    font-weight: 500;
    transition: .3s ease-in-out;
    letter-spacing: -1px;
}


#navbar li a::before {
    counter-increment: numbers;
    content: "0" counter(numbers);
    display: inline-block;
    font-weight: 500;
    width: auto;
    right: 0;
    top: -0.75em;
    line-height: .6em;
    font-size: .7em;
    position: absolute;
    height: auto;
    opacity: .75;
}

#navbar li a:hover {
    color: #fff;
    opacity: 1;
}

.fixed #navbar li a:hover {
    color: #dd00ff;
    opacity: 1;
}

ul li a:not(:hover) {
    opacity: 0.5;
}

ul:not(:hover) li a:only-child {
    opacity: 1;
}

#mobile {
    display: none;
}

#mobile i {
    color: white;
    align-items: center;
}

nav.fixed > a {
    position: static;
    margin: 0;
    color: #dd00ff;
}

@media screen and (max-width: 1280px) {

    body {
        overflow-x: hidden;
        width: 100%;
    }

    #navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: fixed;
        top: 0px;
        right: -300px;
        width: 300px;
        height: 100vh;
        background-color: #1a191d;
        padding: 40px 0 0 10px;
        transition: .3s ease-in-out;
        z-index: 9;
    }

    nav > a {
        position: static;
        margin: 0;
        z-index: 99;
    }

    nav img {
        margin-top: 5px;
        position: absolute;
        left: 20px;
        top: 10px;
    }

    nav {
        display: flex;
        justify-content: space-between;
        padding: 20px 20px;
    }

    nav.fixed {
        position: fixed;
        padding: 20px 40px;
        background: rgba(26, 26, 26, 0.8);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        top: 0;
        width: 100%;
    }

    nav.fixed {
        background-color: #1a191d;
    }

    nav.fixed img {
        display: block;
    }

    #navbar.active {
        right: 0px;
    }

    .fixed #navbar li a {
   
        color: #fff;
  
    }

    #navbar li {
        margin-bottom: 25px;
    }

    #navbar.active li {
        opacity: 0;
        animation: appear 0.5s forwards, moveLeft .2s forwards;
    }

    #navbar li:nth-child(1) {
        animation-delay: 0.1s;
    }

    #navbar li:nth-child(2) {
        animation-delay: 0.2s;
    }

    #navbar li:nth-child(3) {
        animation-delay: 0.3s;
    }

    #navbar li:nth-child(4) {
        animation-delay: 0.4s;
    }

    #navbar li:nth-child(5) {
        animation-delay: .5s;
    }

    @keyframes appear {
        to {
            opacity: 1;
        }
    }

    @keyframes moveLeft {
        from {
            transform: translateX(30px);
        }
        to {
            transform: translateX(0);
        }
    }

    #mobile {
        display: block;
        z-index: 99;
    }

    #mobile i {
        font-size: 24px;
        cursor: pointer;
    }
}

nav > a > img {
    height: 40px;
}

nav > a {
    margin-top: -10px;
    align-items: center;
    margin-left: 40px;
}
