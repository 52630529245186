footer {
    background-color: #1A191D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
}

footer img {
    width: 100px;
    margin: 0 auto;
    margin-bottom: 40px;
}

footer .enlacesFooter {
    font-size: 1.5em;

}

footer .enlacesFooter a {
    color: rgb(255, 255, 255);
    margin: 0 10px;
}

footer p {
    width: fit-content;
    color: white;
    margin-bottom: 25px;
}




@media screen and (max-width: 650px) {
    footer {
        padding: 0 30px 0 30px;
    }

    .copyright {
        font-size: .8em;
        display: grid;    
        grid-template-columns: 1fr;
        text-align: center;
    
    }
    
    .copyright .separador {
        display: none;
    }

}