@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&family=Roboto+Mono&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto Mono', monospace;
    
}

body {
    background-color: #1a191d;
    overflow-x: hidden;
}