section.experiencias {
  background: #1a191d;
    min-height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    z-index: 1;
    
    padding: 0 40px;
    padding-bottom: 100px;
    
}

.experiencias > h2 {
    margin-bottom: 30px;
    margin-top: 100px;
}

.experiencias > h2 > span {
    text-align: center;
    color: white;
    font-family: "Poppins",Sans-serif;
    font-size: 62px;
    font-weight: 600;
    line-height: 1.1em;
    display: block;
}

.accordion {
  max-width: 750px;
  margin: 0 auto;
  width: 100%;
  background-color: #1a191d;
}

.accordion-body {
  background-color: #241d41;
  color: white;
  display: grid;
  grid-template-columns: 80% 20%;
  gap: 1em;
  font-family: 'Roboto Mono', monospace;
  margin: 30px 0;
  padding: 30px !important;
  border-radius: 10px;
}

.accordion-body > div > p {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

.accordion-item {
  background-color: #1a191d !important;
}

.expImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.expImage img {
    width: 90px;
  }

/*Enlaces y localización*/
.expTopInfo {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: fit-content;
  font-size: 15px;
  margin-bottom: 10px;
  color: #ffffffbf;
}

.expTopInfo a {
    color: #ffffffbf;
    text-decoration: none;
}

.expTopInfo a:hover {
    color: #b7f;
    text-decoration: none;
}

.expTopInfo span{
    margin-right: 30px;
  }
  

.expTopInfo i {
  width: 1.25em;
  color: #b7f;
  font-size: 20px;
}
/* 
.expBottomInfo ul {
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 0;
  margin: 0;
  
} */

/* .expBottomInfo > ul > li {
    list-style: none;
    color: #ffffffbf;
    padding: 5px 15px;
    border-radius: 20px;
    background-color: #2c3599;
    font-size: 14px;
    margin-bottom: 0px;
    margin-right: calc(9px/2);
    margin-left: calc(9px/2);
  } */

  .expBottomInfo {
    display: flex;
    flex-wrap: wrap;
    gap: calc(9px / 2); /* Ajusta el espacio entre los elementos */
    justify-content:baseline; /* Centra los elementos */
  }
  
  .expBottomInfo > ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }
  
  .expBottomInfo > ul > li {
    list-style: none;
    color: #ffffffbf;
    padding: 5px 15px;
    border-radius: 20px;
    background-color: #2c3599;
    font-size: 14px;
    margin-bottom: calc(9px / 2); /* Utiliza calc para margen inferior */
    margin-right: calc(9px / 2); /* Utiliza calc para margen derecho */
    margin-left: calc(9px / 2); /* Utiliza calc para margen izquierdo */
  }

  .accordion-button {
    border-radius: 5px!important;
  }

  .accordion-header {
    background-color: #ffffff00!important;
  }

  .accordion-button:not(.collapsed) {
    background-color: #740cdc!important;
    border: none!important;
  }

  .accordion-button {
    background-color: #490C86DE!important;
    border: none!important;
  }

  .accordion-button::after {
    content: "+"!important;
    color: white;
    font-size: 20px;
    transform: none!important;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
  }

  .accordion-button:not(.collapsed)::after {
    content: "-"!important;
    color: white;
    font-size: 20px;
    background-image: none!important;
    transform: none!important;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
 
  }

  .accordion-button span{
    display: flex!important;
    justify-content: space-between;
    width: 100%;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.05em;
    line-height: 1.3;
    letter-spacing: 0;
  }

  .accordion-item {
    margin-bottom: 30px;
    border: none!important;
  }

@media screen and (max-width: 725px) {

  #experiencias {
    min-height: 20vh;
  }

    section.experiencias {
        padding: 0 20px;
    }

    .experiencias > h2 > span{
        font-size: 10vw;
    }

    .accordion {
        max-width: none;
        width: 100%;
        margin: 0!important;
    }

    .accordion-button > span > span {
        font-size: 14px;
        font-weight: 400!important;
    }

    .accordion-button span > span:nth-child(2) {
        display: none!important;
    }

    .accordion-body {
        grid-template-columns: 1fr;
        
    }

    .expImage img {
       width: 70px;
    }

    .accordion-body > div:first-child {
        /* grid-area: b; */
        grid-row: 2; /* Coloca el segundo elemento en la segunda fila */

    }
/* .expBottomInfo > ul > li {
    margin-right: calc(-8px/2);
    margin-left: calc(-8px/2);
} */

.expBottomInfo > ul > li {
  width: auto; /* Permite que los elementos se ajusten automáticamente al contenido */
  margin: calc(9px / 2); /* Ajusta los márgenes para mantener el espaciado */
}
}
      
        

















