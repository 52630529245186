/* Estilos generales */
section.proyectos {
    background: #1a191d;
    min-height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    z-index: 1;
    padding: 0 40px;
    padding-top: 300px;
    padding-bottom: 100px;
    
    
  }

  .proyectos > h2 {
    margin-bottom: 70px;
    margin-top: 100px;
  }
  
  .proyectos > h2 > span {
    text-align: center;
    color: white;
    font-family: "Poppins", Sans-serif;
    font-size: 62px;
    font-weight: 600;
    line-height: 1.1em;
    display: block;
  }

  .proyectos aside {
    display: grid;
    grid-template-columns: 40% 60%;
    color: white;
    width: 100%;
    max-width: 1250px;
  }


  .proyectos aside h5 {
    color: #fff;
    font-family: "Roboto Mono", Sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2em;
  }

  .btnProyecto {
    font-family: "Roboto Mono", Sans-serif;
    background-color: #9b37ff;
    border-radius: 3px;
    padding: 15px 24px;
    color: white;
    text-decoration: none;
  }

  .btnProyecto:hover {
    color: white;
  }

  #flechaProyecto {
    width: 150px;
    margin-left: 40px;
    margin-bottom: 30px;
    margin-top: -20px;
  }

  #proyectos aside h5 {
    place-self: center;
  }

  #proyectos aside div {
    width: fit-content;
    margin-left: 75px;
    position: relative;
  }

  #oceanwifi {
    animation: float 6s ease-in-out infinite;
    position: absolute;
    top: -200%;
    left: 100px;
  }

  .tituloProyecto {
    font-family: "Poppins", Sans-serif;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0;    
  }
  
  .proyectoDestacado {
    font-family: "Roboto Mono", Sans-serif;
    font-size: 30px;
    font-weight: 600;
  }

  @keyframes float {
    0% {
      /* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
      transform: translatey(0px);
    }
    50% {
      /* box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2); */
      transform: translatey(-50px);
    }
    100% {
      /* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
      transform: translatey(0px);
    }
  }

  @media screen and (max-width: 1320px) {
    #oceanwifi {
      display: none;

    }

    #flechaProyecto {
      display: none;
    }
  }

  @media screen and (max-width: 930px) {

    section.proyectos {
      padding-top: 200px;
      height: 30vh;
    }
    .proyectos aside {
      grid-template-columns: 1fr;
    }

    .proyectos aside h5 {
      font-size: 1.2em;
    }
    
    #proyectos aside div {
      width: 100%;
      margin-left: 0;
      margin-top: 30px;
    }

    .tituloProyecto {
      font-size: 4vw;
      
    }

    .proyectoDestacado {
      font-size: 5vw;
      margin-bottom: 30px;
    }

  }
  