#tecnologias {
    background: #1a191d;
    min-height: 70vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    flex-direction: column;
    z-index: 1;
    padding: 0 40px;
}

#tecnologias h2 {
    text-align: center;
    color: white;
    font-family: "Poppins",Sans-serif;
    font-size: 75px;
    font-weight: 600;
    line-height: 1.4em;
}

.tecnologias {
    display: flex;
    max-width: 1100px;
    width: 100%;
    padding: 0;
    margin: 30px 0;
}

.tecnologias > div {
    padding: 40px 30px;
    border-style: solid;
    border-width: 3px 1.5px 3px 3px;
    border-color: #a3a3a3;
    width: 33.33%;
}

.tecnologias h3 {
    color: white;
    font-family: "Poppins",Sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.2em;
   
}

.tecnologias h3 span {
    display: block;
    margin-left: 10px;
    transition: all 0.1s ease-in-out;
    z-index: 0;
    position: relative;
    width: fit-content;
}

.tecnologias h3 .magentaUnderline::after {
    display: block;
    margin-left: 10px;
    transition: all 0.1s ease-in-out;
    content: "";
    position: absolute;
    bottom: 13.5%;
    z-index: -1;
    height: 0.3em;
    width: 104%;
    left: -7%;
    background: linear-gradient(65deg, #DF058D 0%, #DF058D 100%, rgba(255, 209, 0, 0) 100%);
}

.tecnologias h3 .reactUnderline::after {
    display: block;
    margin-left: 10px;
    transition: all 0.1s ease-in-out;
    content: "";
    position: absolute;
    bottom: 13.5%;
    z-index: -1;
    height: 0.3em;
    width: 104%;
    left: -7%;
    background: linear-gradient(65deg, #149ECA 0%, #149ECAe3 100%, rgba(255, 209, 0, 0) 100%);
}

.tecnologias h3 .laravelUnderline::after {
    display: block;
    margin-left: 10px;
    transition: all 0.1s ease-in-out;
    content: "";
    position: absolute;
    bottom: 13.5%;
    z-index: -1;
    height: 0.3em;
    width: 104%;
    left: -7%;
    background: linear-gradient(65deg, #F9322C 0%, #f9332ce3 100%, rgba(255, 81, 0, 0) 100%);
}


.techSubtitles {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 15px;
}

.techDesc > p::before {
    content: "<h3>";
    opacity: 0.3;
    color: white;
    display: block;
    margin-left: -35px;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 5px;
}

.techDesc > p::after {
    content: "<h3>";
    opacity: 0.3;
    color: white;
    display: block;
    margin-left: -35px;
    font-weight: normal;
    font-size: 14px;
    margin-top: 5px;
}

.techDesc {
    color: white;
    font-family: "Roboto Mono";
    padding-left: 35px;
    line-height: 1,4em;
    position: relative;
}

.techDesc::after {
    opacity: 0.3;
    content: '';
    width: 0;
    height: 65%;
    position: absolute;
    background-color: white;
    border: 0.1px solid white;
    bottom: 17%;
    left: 17px;
}

.techSubtitles i {
    font-size: 40px;
    color: white;
}

.codeImg {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    user-select: none;
}

.codeImg > img {
    width: 580px;
    opacity: .3;
    margin-top: -50px;
}

@media screen and (max-width: 1280px) {
    .tecnologias {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .tecnologias > div {
        padding: 40px 30px;
        border-style: solid;
        border-width: 3px 1.5px 3px 3px;
        border-color: #a3a3a3;
        width: 100%;
    }

    
}
@media screen and (max-width: 725px) {
    .tecnologias {
        display: grid;
        grid-template-columns: 1fr ;

    }

    .tecnologias > div {
        padding: 40px 30px;
        border-style: solid;
        border-width: 3px 1.5px 3px 3px;
        border-color: #a3a3a3;
        width: 100%;
        margin-bottom: 10px;
    }

    #tecnologias h2 {
        font-size: 10vw;
    }

    .codeImg > img {
        width: 100%;
        opacity: .6;
        margin-top: -70px;
        display: none;
    }

}

